import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 4×8\\@75% 1RM`}</p>
    <p>{`Strict Pullups 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time:`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`40-Pushups`}</p>
    <p>{`30-KBS’s (53/35)(RX+70/53)`}</p>
    <p>{`20-GHD Situps `}<em parentName="p">{`(scale to v ups)`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      